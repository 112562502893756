// React imports
import React from 'react'

// Remix imports

// Third party imports

// Generated imports

// App imports
import IconCameraFilled from '~/static/svg/IconCameraFilled'
import IconSearch from '~/static/svg/IconSearch'
import type { Props as InputTextProps } from './InputText'
import InputText from './InputText'

interface Props extends InputTextProps {
  trans: { Search: string }
  showCamera?: boolean
  searchDark?: boolean
  handleCamera?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  inputClassName?: string
}

const InputTextSearch: React.FC<Props> = ({
  children,
  className,
  disabled,
  trans,
  showCamera = false,
  searchDark = false,
  handleCamera,
  inputClassName = '',
  ...other
}) => {
  return (
    <div className={'relative w-full' + (className ? ` ${className}` : '')}>
      <InputText
        marginBottom="mb-0"
        inputClassName={'pl-[30px] ' + inputClassName}
        disabled={disabled}
        iconRight={
          <div className="pointer-events-none absolute left-0 top-0 h-full w-full">
            <button
              className=" absolute left-[8px] top-0 flex h-full flex-col items-center justify-center disabled:cursor-not-allowed"
              disabled={disabled}
            >
              <IconSearch
                className={
                  'h-[16px] w-[16px] transition-all ' +
                  (searchDark ? 'fill-black ' : 'fill-brand-bg-border ')
                }
                style={{
                  filter: disabled ? 'contrast(0%)' : undefined,
                  opacity: disabled ? '0.5' : undefined,
                }}
              />
            </button>
            {showCamera && (
              <button
                className="pointer-events-auto absolute right-[8px] top-0 flex h-full cursor-pointer flex-col items-center justify-center disabled:cursor-not-allowed"
                disabled={disabled}
                onClick={handleCamera}
              >
                <IconCameraFilled
                  className="h-[16px] w-[16px] fill-brand-bg-border transition-all hover:fill-icon-dark"
                  style={{
                    filter: disabled ? 'contrast(0%)' : undefined,
                    opacity: disabled ? '0.5' : undefined,
                  }}
                />
              </button>
            )}
          </div>
        }
        {...other}
      />
    </div>
  )
}

export default InputTextSearch
