import type { SVGProps } from 'react'

const IconCameraFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 20"
    fill="none"
    {...props}
  >
    <path d="M8.571 11.25c0-1.348.836-2.564 2.117-3.08a3.5 3.5 0 0 1 3.737.723 3.266 3.266 0 0 1 .742 3.633c-.53 1.245-1.78 2.057-3.167 2.057-1.893 0-3.429-1.492-3.429-3.333ZM24 5.833V17.5c0 1.38-1.152 2.5-2.571 2.5H2.57C1.151 20 0 18.88 0 17.5V5.833c0-1.38 1.152-2.5 2.571-2.5H4.08a.86.86 0 0 0 .771-.458l.763-1.492A2.58 2.58 0 0 1 7.92 0h8.16a2.58 2.58 0 0 1 2.306 1.383l.762 1.492a.86.86 0 0 0 .772.458h1.508c1.42 0 2.572 1.12 2.572 2.5ZM3.429 7.5c0 .22.09.433.25.59a.87.87 0 0 0 .607.243h.857A.845.845 0 0 0 6 7.5a.845.845 0 0 0-.857-.833h-.857a.845.845 0 0 0-.857.833Zm3.428 3.75c0 1.326.542 2.598 1.507 3.535A5.219 5.219 0 0 0 12 16.25a5.219 5.219 0 0 0 3.636-1.465 4.93 4.93 0 0 0 1.507-3.535 4.931 4.931 0 0 0-1.507-3.536A5.219 5.219 0 0 0 12 6.25a5.219 5.219 0 0 0-3.636 1.464 4.931 4.931 0 0 0-1.507 3.536Z" />
  </svg>
)
export default IconCameraFilled
